<template>
  <div>
    <treeview-root
      :items="itemsAll"
      :openItems="openNodes"
      v-model="itemsChecked"
      @input="onCheckboxClicked"
      selected-color="teal lighten-1"
      item-key="id"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import TreeviewRoot from '@/components/Treeview/Treeview.vue';

export default {
  components: {
    TreeviewRoot,
  },
  props: {
    filterType: {
      type: String,
      required: true,
    },
    openNodes: {
      type: Array,
      required: false,
    },
    queryStringRoute: {
      type: String,
      required: false,
    },
    addRootNode: {
      type: Boolean,
      required: false,
      default: false,
    },
    syncQueryString: {
      type: Boolean,
      required: true,
      default: false,
    },
    allowSelectAll: {
      type: Boolean,
      required: false,
      default: false,
    },
    dispatchOnCheckboxClicked: {
      type: String,
      required: false,
    },
    storeGetter: {
      type: String,
      required: true,
    },
    storeSetter: {
      type: String,
      required: true,
    },
  },

  data: () => ({
    tmp: true,
  }),

  computed: {
    ...mapGetters({
      filters: 'userFilters',
    }),

    itemsAll() {
      const filters = [...this.filters[this.filterType]];

      if (this.addRootNode) {
        return [
          {
            id: 0,
            name: this.$t('generic.selectAll'),
            children: filters,
          },
        ];
      }

      return filters;
    },

    itemsChecked: {
      get() {
        return this.$store.getters[this.storeGetter];
      },
      set(checkedFilters) {
        this.$store.dispatch(this.storeSetter, checkedFilters);
      },
    },
  },

  methods: {
    ...mapState(['searchParameters']),

    onCheckboxClicked() {
      if (this.dispatchOnCheckboxClicked) {
        this.$store.dispatch(this.dispatchOnCheckboxClicked);
      }
    },
  },

  mounted() {
    if (this.syncQueryString && this.$route.query.filters) {
      this.itemsChecked = this.$route.query.filters.split(',');
      this.onCheckboxClicked();
    }
  },
};
</script>

<style lang="scss" scoped>
  .treeview ::v-deep .line{
    margin-bottom: 16px;
  }
</style>
